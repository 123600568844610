.navbar {
	height: 84px;
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	padding: 0;
	margin: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	border-bottom: 2px solid transparent;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	z-index: 98;

	@media only screen and (max-width: @screen-mobile-max) {
		height: 55px;
	}

	&.shadow {
		box-shadow:
			0px 4px 4px 0px rgba(0, 0, 0, 0.25),
			0px -4px 14px 0px #132a3f inset;
		backdrop-filter: blur(15px);
	}

	&.scrolled {
		position: fixed;
		top: -84px;
		-webkit-animation: slide 0.5s forwards;
		-webkit-animation-delay: 2s;
		animation: slide 0.5s forwards;
		animation-delay: 0s;
		background: @black;
	}

	.content {
		display: flex;
		align-items: center;
		column-gap: 80px;

		@media only screen and (max-width: @screen-xl) {
			column-gap: 34px;
		}

		@media only screen and (max-width: @screen-mobile-max) {
			column-gap: 16px;
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.menu {
			display: flex;
			align-items: center;
			column-gap: 32px;

			a {
				color: @white;
				font-size: 16px;
				font-weight: 500;
			}
		}

		.right-section {
			width: 100%;
			display: flex;
			align-items: center;
			column-gap: 42px;

			@media only screen and (max-width: @screen-tablet-max) {
				column-gap: 34px;
			}

			@media only screen and (max-width: @screen-mobile-max) {
				column-gap: 16px;
			}

			.earn-btn {
				display: flex;
				align-items: center;
				padding: 7.5px 27px;
				gap: 12px;
				border-radius: 4px;
				border: 1px solid @white;
				background: transparent;
				color: @white;
				font-size: 16px;
				font-weight: 500;

				@media only screen and (max-width: @screen-mobile-max) {
					padding: 6px 10px;
				}
			}

			.account {
				display: flex;
				align-items: center;
				border-radius: 50%;
				border: 2px solid #139efb;
			}

			.earn-list {
				display: flex;
				flex-direction: column;
				background: #1c1e24;
				width: 329px;
				border-radius: 8px;
				margin-top: 24px;
				border: 1px solid #383c48;

				@media only screen and (max-width: @screen-xxl) {
					width: 230px;
					margin-top: 15px;
				}

				@media only screen and (max-width: @screen-xl) {
					width: 200px;
				}

				@media only screen and (max-width: @screen-md) {
					width: 170px;
					margin-top: 5px;
				}
			}

			.earn-item {
				padding: 15px 16px;
				font-size: 16px;
				font-weight: 700;
				color: #ccc;
			}
			.earn-item:hover {
				background-color: #2c2e39;
			}
		}
	}
}

.sidebar {
	min-width: 248px;
	min-height: 291px;
	display: flex;
	flex-direction: column;
	row-gap: 17px;
	padding: 33px 22px 60px;
	border: 1px solid #2b2b2b;
	background: linear-gradient(180deg, #002d70 0%, #0c4396 100%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(2px);

	a {
		color: @white;
		font-size: 24px;
		font-weight: 700;
	}

	.divider {
		margin: 8px 0;
	}
}

body:has([class~='header-connect-wallet']) {
	.navbar {
		top: 32px;

		&.scrolled {
			top: -84px;
			-webkit-animation: slideW 0.5s forwards;
			animation: slideW 0.5s forwards;
		}
	}
}

@-webkit-keyframes slide {
	100% {
		top: 0;
	}
}

@keyframes slide {
	100% {
		top: 0;
	}
}

@-webkit-keyframes slideW {
	100% {
		top: 32px;
	}
}

@keyframes slideW {
	100% {
		top: 32px;
	}
}


@import '/workspace/src/styles/variables.less';